import * as yup from 'yup';
export const validateEmail = yup.string().trim().required('Enter email id').email('Enter valid email id');
export const validatePhone = yup
  .string()
  .trim()
  .required('Enter phone number')
  .min(10, 'Enter valid phone number')
  .max(10, 'Enter valid phone number')
  .matches('^[1-9][0-9]{9}$', 'Enter valid phone number');

export const addRequest = yup
  .object()
  .shape({
    // sel_block: yup
    // .string()
    // .trim()
    // .required('Select Amount'),
    name: yup
    .string()
    .trim()
    .required('Enter name')
    .max(100, 'last name should not be more than 100 characters'),
    email: validateEmail,
    // phone: validatePhone
  })
  .defined();

export const contactRequest = yup
  .object()
  .shape({
    first_name: yup
    .string()
    .trim()
    .required('Enter first name')
    .max(100, 'last first name should not be more than 100 characters')
    .matches("^[ A-Za-z0-9.,'-]*$", 'Enter valid first name'),
    last_name: yup
    .string()
    .trim()
    .required('Enter last name')
    .max(100, 'last last name should not be more than 100 characters')
    .matches("^[ A-Za-z0-9.,'-]*$", 'Enter valid last name'),
    email: validateEmail,
    //phone: validatePhone,
    message: yup
    .string()
    .trim()
    .required('Enter message')
    .max(1000, 'last message should not be more than 1000 characters')
    .matches("^[ A-Za-z0-9.,'-]*$", 'Enter valid message'),
  })
  .defined();
