import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';


const CounterBlog = () => {
    const { t } = useTranslation();
    const counterData = [
        {number:"2000", title: t('home.counter_section.total_donor') },
        {number:"978", title: t('home.counter_section.supportes') },
        {number:"7", title: t('home.counter_section.customers') },
        // {number:"852", title:"Volunteer" },
    ];

    return (
        <>
          {counterData.map((data, index)=>(
            <div className="col-lg-4 col-6 m-b30" key={index}>
                <div className="counter-style-1 text-center">
                    <span className="counter counter-num">
                        <CountUp 
                            end={data.number} 
                            separator = ","
                            duration= "3"
                        />
                    </span>
                    <p className="counter-text">{data.title}</p>
                </div>
            </div>  
          ))}
        </>
    );
};


export default CounterBlog;