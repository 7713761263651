import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import PageBanner from "../../layouts/PageBanner";
import bg from "../../assets/images/banner/bnr4.jpg";
import { postApi } from "../../services/appUtilsAxios";
import { showMessage } from "../../services/helper";

export default function ThankYou() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const payment_intent = searchParams.get('payment_intent')
  const payment_intent_client_secret = searchParams.get('payment_intent_client_secret');
  const redirect_status = searchParams.get('redirect_status');

  useEffect(() => {
    let purchase_info = localStorage.getItem("purchase_info");
    purchase_info = JSON.parse(purchase_info);
    purchase_info.payment_info = { payment_intent, payment_intent_client_secret, redirect_status };
    postApi('add-raise-request', purchase_info)
    .then((res)=>{
      if(res.status === 1){
        showMessage(1, "Fund raised successfull");
        localStorage.removeItem("purchase_info");
        setTimeout(()=>{
          return navigate(`/product-details/${purchase_info?.product_id}`);
        }, 5000);
      } else {
        showMessage(0, res?.message || "Something went wrong");
      }
    })
    .catch((error)=>{
      console.log('error', error)
      showMessage(0, error?.message);
    });
  }, []);
  return (
    <div className="page-content bg-white">
        <PageBanner
          maintitle={ t('menu.home') }
          pagetitle={ t('thank_you.title') }
          background={bg}
        />
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 m-b30">
                <div className="alert alert-success" role="alert">
                  { t('thank_you.content') }<br/>
                  { t('thank_you.content_line2') }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    
  );
}