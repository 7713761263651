import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import enLang from './assets/translations/en.json';
import geLang from './assets/translations/ge.json';

export const lngs = [
  { value: "en", label: "English" },
  { value: "ge", label: "Deutsch" },
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'ge', // default language
    fallbackLng: 'ge',
    preload: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: enLang
      },
      ge: {
        translation: geLang
      },
    }
  });

export default i18n;
