import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from 'react-i18next';

// Import Swiper styles
//import "swiper/css";

//Images
import pic1 from './../../assets/images/testimonials/pic1.jpg';
import pic2 from './../../assets/images/testimonials/pic2.jpg';
import pic3 from './../../assets/images/testimonials/pic3.jpg';

// import Swiper core and required modules
import { Autoplay, Navigation, Pagination } from "swiper";

//SwiperCore.use([EffectCoverflow,Pagination]);

/* const dataBlog = [
	{image: pic1, title:"Johan Lee"},
	{image: pic2, title:"Lee Jordon"},
	{image: pic3, title:"Alex Costa"}
]; */



const TestimonialSlider = () => {

	const { t } = useTranslation();

	const dataBlog = [
		{ desc: t('home.testimonials.testi1'), title:"Testimonial One" },
		{ desc: t('home.testimonials.testi2'), title:"Testimonial Two" },
		{ desc: t('home.testimonials.testi3'), title:"Testimonial Three" },
		{ desc: t('home.testimonials.testi4'), title:"Testimonial Four" }
	];
	
	const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)
    return (
        <>
            <Swiper className="testimonial-swiper"	
				speed= {1500}
				//parallax= {true}
				slidesPerView={"auto"}
				spaceBetween= {0}
				centeredSlides= {true}
				loop={true}
				autoplay= {{
				   delay: 3000,
				}}
				modules={[ Autoplay ,Navigation,  Pagination]}
				onSwiper={(swiper) => {
					setTimeout(() => {
						swiper.params.navigation.prevEl = navigationPrevRef.current
						swiper.params.navigation.nextEl = navigationNextRef.current
						swiper.navigation.destroy()
						swiper.navigation.init()
						swiper.navigation.update()
					})
				}}
			>	
               
				{dataBlog.map((d,i)=>(
					<SwiperSlide key={i}>						
            <div className="testimonial-1">
							<div className="testimonial-text">
								<p>{d.desc}</p>
							</div>
							<div className="testimonial-details">
								{/* <div className="testimonial-info">
									<div className="testimonial-pic">
										<img src={d.image} alt="" />
									</div>
									<div className="clearfix">
										<h5 className="testimonial-name">{d.title}</h5>
										<span className="testimonial-position">Joe’s Parents</span>
									</div>
								</div> */}
								<div className="testimonial-rating">
									{/* <ul>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star"></i></li>
										<li><i className="fa fa-star gray-light"></i></li>
										<li><i className="fa fa-star gray-light"></i></li>
									</ul> */}
								</div>
							</div>
						</div>				
					</SwiperSlide>
					
				))}		
				<div className="swiper-btn container swiper-btn-center-lr">
					<div className="test-swiper-prev btn-prev style-1" ref={navigationPrevRef}><i className="fa fa-arrow-left-long"></i></div>
					<div className="test-swiper-next btn-next style-1" ref={navigationNextRef}><i className="fa fa-arrow-right-long"></i></div>
				</div>	
			</Swiper>
        </>
    );
};


export default TestimonialSlider;