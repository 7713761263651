import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageBanner from "./../layouts/PageBanner";

//images
import bgImage from "./../assets/images/banner/bnr1.jpg";
/* import pic1 from "./../assets/images/main-slider/slider2/pic1.png";
import about from "./../assets/images/about/pic1.jpg";
import signature from "./../assets/images/about/signature.png";
import bg4 from "./../assets/images/background/bg4.jpg"; */
import trustIcon from "./../assets/images/trust-icon.png";

//import shape1 from './../assets/images/pattern/shape1.png';
//import shape2 from './../assets/images/pattern/shape2.png';
//import shape3 from './../assets/images/pattern/shape3.png';
//import shape5 from './../assets/images/pattern/shape5.png';
//import shape6 from './../assets/images/pattern/shape6.png';
// import NewsSlider from "../components/Home/NewsSlider";
import UpdateBlog from "../components/Home/UpdateBlog";
// import OurMission from "../components/OurMission";
// import { IMAGES } from "../constant/theme";
import { Helmet } from "react-helmet";

const iconBlog = [
  { title: "Trusted Partner" },
  // {title:"Responsibility"}
];

const AboutUs = () => {
  const { t } = useTranslation();
  const wrapperBlog = [
    {
      isImg:false,
      icon: "flaticon-vr-glasses",
      title: t("aboutus.section2.box1.title"),
      description: t("aboutus.section2.box1.description"),
    },
    {
      isImg:false,
      icon: "flaticon-transformation",
      title: t("aboutus.section2.box2.title"),
      description: t("aboutus.section2.box2.description"),
    },
    { 
      isImg:false,
      icon: "flaticon-doctor-bag",
      title: t("aboutus.section2.box3.title"),
      description: t("aboutus.section2.box3.description"),
    },
    { 
      isImg:true,
      icon: "flaticon-open-book",
      title: t("aboutus.section2.box4.title"),
      description: t("aboutus.section2.box4.description"),
    },
  ];
  return (
    <>
    <Helmet>
<link rel="canonical" href='https://columpix.com/about-us' />
  <script type="application/ld+json">
  {`
  {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "https://columpix.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "About Us",
      "item": "https://columpix.com/contact-us"  
    }]
  }
             
  `}
  </script>
  </Helmet>
      <div className="page-content bg-white">
        <PageBanner
          maintitle={t("menu.home")}
          pagetitle={t("menu.aboutus")}
          background={bgImage}
        />
        <section className="content-inner section-wrapper5 about-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="section-head">
                  <h2 className="sub-title"><img src="info.svg" alt="{t('aboutus.section1.title')}" className="icon-cell-img" />{t('aboutus.section1.title')}</h2>
                  {/* <h2>Experienced more than 25 Years in Crowdfunding</h2> */}
                  <p className="info_des">
                  {t('aboutus.section1.text1')}<br/>
                  {t('aboutus.section1.text2')}<br/>
                  {t('aboutus.section1.text3')}<br/>
                  {t('aboutus.section1.text4')}<br/>
                  {t('aboutus.section1.text5')}
                  </p>
                </div>
                {iconBlog.map((data, index) => (
                  <div
                    className="icon-bx-wraper box-hover style-2 m-b30"
                    key={index}
                  >
                    <div className="icon-lg">
                      {/* <Link to={"/services-details"} className="icon-cell">
                        <i className="flaticon-shield"></i>
                      </Link> */}
                      <a href="https://www.mister-trust.org/" target="_blank">
                        <img src={trustIcon} alt="Truster Partner" className="icon-cell-img" />
                      </a>
                    </div>
                    <div className="icon-content">
                      <h4 className="dz-tilte m-b5 text-capitalize">
                        {data.title}
                      </h4>
                      {/* <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.{" "}
                      </p> */}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-6 m-b30">
                <div className="about_media">
                  {/* <img src={pic1} alt="image" /> */}
                  <img src="/GIF-2.gif" alt="About Us" title="About Us" />
                </div>
              </div>
            </div>
            <div className="row align-items-center m-t30 about-cont">
              <div className="col-lg-6 order_2">
                <div className="about_media">
                  <img src="/Columpix.com.gif" alt="About Us" title="About Us"/>
                </div>
              </div>
              <div className="col-lg-6 order_1">
                <div className="section-head">
                  <h2 className="sub-title"><img src="owner.svg" alt="{t('aboutus.section3.title')}" className="icon-cell-img" /> {t('aboutus.section3.title')}</h2>
                  <p className="info_des">
                  {t('aboutus.section3.text1')}<br/>
                  {t('aboutus.section3.text2')}
                  </p>
                </div>
              </div>
              <div className="col-lg-12 order_2">
                <p className="info_des">{t('aboutus.section3.text3')}</p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="content-inner-2 overlay-primary-dark about-wrapper2 bg-img-fix" style={{backgroundImage:"url("+ bg4 +")",  backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                    <div className="container">
                        <div className="about-bx2">
                            <div className="row g-0">
                                <div className="col-lg-4">
                                    <div className="dz-media">
                                        <img src={about} alt="image" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="dz-info">
                                        <h2 className="title m-b30 m-md-b20">Fundraising is the gentle art of teaching the joy of giving</h2>
                                        <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                        <div className="signature">
                                            <img src={signature} alt="image" />
                                            <p className="text-dark m-t15 mb-0">William Durant</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
        <section className="content-inner about-wrapper pd-top">
          <div className="container">
            <div className="section-head text-center">
              <h5 className="sub-info">{ t("aboutus.section2.heading") }</h5>
              <h2 className="m-b20 text-capitalize sub-title">{ t("aboutus.section2.title") }</h2>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis no</p> */}
            </div>
            <div className="row">
              {wrapperBlog.map((item, ind) => (
                <div className="col-lg-6 col-md-12 col-sm-12" key={ind}>
                  <div className="icon-bx-wraper box-hover style-2 m-b30">
                    <div className="icon-lg">
                      {item.isImg ? <a href="http://mister-trust.org/" target="_blank">
                        <img src={trustIcon} alt="Truster Partner" title="Truster Partner" className="icon-cell-img" />
                      </a> : <Link to={"/"} className="icon-cell">
                      <i className={item.icon}></i>
                      </Link>} 
                    </div>
                    <div className="icon-content">
                      <h4 className="dz-tilte m-b10 text-capitalize">
                        {item.title}
                      </h4>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        
          {/* <div className="container">
                <div className="row about-bx3 align-items-center">
                    <OurMission />
                </div>
            </div>
            <img src={IMAGES.Shape1} className="shape-1 move-1" alt="shape" />
            <img src={IMAGES.Shape2} className="shape-2 move-2" alt="shape" />
            <img src={IMAGES.Shape3} className="shape-3 move-1" alt="shape" />
            <img src={IMAGES.Shape5} className="shape-4 rotating" alt="shape"/>
            <img src={IMAGES.Shape6} className="shape-5 rotating" alt="shape"/>
            <img src={IMAGES.Shape6} className="shape-6 rotating" alt="shape"/> */}

        {/* <section className="content-inner-2">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">Akcel News</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam.
              </p>
            </div>
            <NewsSlider />
          </div>
        </section> */}
        
      </div>
    </>
  );
};

export default AboutUs;
