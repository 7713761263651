import React,{useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

import PageBanner from '../layouts/PageBanner';
import ProjectMasonry from '../components/Project/ProjectMasonry';
import UpdateBlog from '../components/Home/UpdateBlog';

import bg from '../assets/images/banner/bnr5.jpg';
import { getApi } from '../services/appUtilsAxios';
import ProjectCard from '../components/Project/ProjectCard';
import { Helmet } from "react-helmet";



const Project = () => {
    const { t } = useTranslation();
    //const [dropbtn2,setDropbtn2] = useState('All Category');
    const [projects, setProjects] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const getProjects = async (cPage = 1) => {
        await getApi(`get-projects?page=${cPage}`).then((resp)=>{
            console.log('resp', resp);
            setProjects(resp?.docs);
            setTotalPage(resp?.totalPages);
        })
    };
    useEffect(() => {
        getProjects(page);
    }, []);

    const doPagination = async (pageNo) => {
        setPage(pageNo);
        getProjects(pageNo);
    };
    return (
        <>
            <Helmet>
<link rel="canonical" href='https://columpix.com/products' />
  <script type="application/ld+json">
  {`
  {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "https://columpix.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Products",
      "item": "https://columpix.com/products"  
    }]
  }
             
  `}
  </script>
  </Helmet>
  
            <div className="page-content bg-white">
                <PageBanner maintitle={ t('menu.home') } pagetitle={ t('menu.products') } background={bg}/>
                <div className="find-bx-wrapper">
                                    </div>
                <section className="content-inner-2 mb-1 project-page">
			        <div className="container">
                        {projects.length > 0 && <ProjectCard projects={projects} 
                        total={totalPage}
                        current={page}
                        pagination={doPagination}
                        />}
                    </div>
                </section>
                
            </div>
        </>
    );
}; 

export default Project;
