import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { currency, stripeConfig } from "../../constant/theme";
import { postApi } from "../../services/appUtilsAxios";
import { showMessage } from "../../services/helper";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(stripeConfig.privateKey);

export default function Stripe() {
  const [clientSecret, setClientSecret] = useState("");
  const [purchaseInfo, setPurchaseInfo] = useState();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // setClientSecret("pi_3NGQGvAlHXAgqBFp1uVHCvLa_secret_ciPCEWHJkvUneWYYKB8JCdz3G");
    // return false;
    if(localStorage.getItem("purchase_info")){
      const purchase_info = JSON.parse(localStorage.getItem("purchase_info"));
    
      const total_price = purchase_info?.total_price;
      setPurchaseInfo(purchase_info);
      const sdata = { amount: total_price };
      postApi('create-payment-intent', sdata)
        .then((res)=>{
          if(res.status === 1){
            setClientSecret(res?.data.clientSecret)
            console.log(res)
            // showMessage(1, res?.message || "Request sent successfull");
          }
        })
        .catch((error)=>{
          console.log('error', error)
          showMessage(0, error?.message);
        });
    }
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm purchaseInfo={purchaseInfo} />
        </Elements>
      )}
    </div>
  );
}