import axios from 'axios';
import handleError from './handleError';

const apiBaseUrl = `${process.env.REACT_APP_API_URL}user/`;

function getToken() {
  return localStorage.getItem('token');
}

export const getHeaders = () => {
  return {
    headers: {
      Accept: 'application/json',
      'content-Type': 'application/json',
      /* ...(getToken() && {
        authorization: `Bearer ${getToken()}`
      }), */
    }
  };
};

export const postApi = (path, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiBaseUrl}${path}`, data, getHeaders())
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};

// export const getApi = (path) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${apiBaseUrl}${path}`, getHeaders())
//       .then((response) => {
//         resolve(response?.data?.data);
//       })
//       .catch((error) => {
//         reject(handleError(error));
//       });
//   });
// };

// import axios from 'axios';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getApi = (path, retries = 3, delayTime = 1000) => {
  return new Promise(async (resolve, reject) => {
    let attempt = 0;

    // Loop to attempt retries
    while (attempt < retries) {
      try {
        const response = await axios.get(`${apiBaseUrl}${path}`, getHeaders());
        resolve(response?.data?.data);
        break; // Exit loop if successful
      } catch (error) {
        attempt++;
        if (attempt >= retries) {
          reject(handleError(error)); // Reject if max retries reached
          break;
        }
        console.log(`Retry attempt ${attempt} failed. Retrying...`);
        await delay(delayTime); // Wait before retrying
      }
    }
  });
};
