import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import "./stripe.css";
import PageBanner from "../../layouts/PageBanner";
import bg from "../../assets/images/banner/bnr4.jpg";
import { currency } from "../../constant/theme";

export default function CheckoutForm({ purchaseInfo }) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log('paymentIntent', paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_BASE_URL}/thank-you`,
      },
    });
    console.log('error', error);
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <div className="page-content bg-white">
        <PageBanner
          maintitle={ t('menu.home') }
          pagetitle={ t('menu.payment') }
          background={bg}
        />
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              <div className="col-xl-2 col-lg-2 m-b30">
              </div>
              <div className="col-xl-7 col-lg-7 m-b30">
              {purchaseInfo && (<>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th colSpan={2} className="text-center">{ t('payment.table_heading') }</th>
                    </tr>
                    <tr>
                      <td>{ t('payment.project_name') }</td>
                      <td>{purchaseInfo?.project_name}</td>
                    </tr>
                    <tr>
                      <td>{ t('payment.amount') }</td>
                      <td>{currency}{purchaseInfo?.price}</td>
                    </tr>
                    <tr>
                      <td>{ t('payment.donate_amount') }</td>
                      <td>{currency}{purchaseInfo?.donate_price}</td>
                    </tr>
                    <tr>
                      <td>{ t('payment.payble_amount') }</td>
                      <td>{currency}{purchaseInfo?.total_price}</td>
                    </tr>
                  </tbody>
                </table>
                </>)}
              <form id="payment-form" onSubmit={handleSubmit}>
                {/* <LinkAuthenticationElement
                  id="link-authentication-element"
                  onChange={(e) => setEmail(e.target.value)}
                /> */}
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-primary btn-sm">
                  <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                  </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
              </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    
  );
}