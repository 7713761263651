import React from 'react';
import {  Route, Routes, Outlet  } from 'react-router-dom';

import ScrollToTop from './../layouts/ScrollToTop';
import Header from './../layouts/Header';
import Footer from './../layouts/Footer';
import Home from './Home';
import AboutUs from './AboutUs';
import Error from './Error';
import UnderMaintenance from './UnderMaintenance';
import ComingSoon from './ComingSoon';
import FundraiserDetail from './FundraiserDetail';
import Project from './Project';
import ContactUs from './ContactUs';
import Stripe from '../components/Stripe/Stripe';
import ThankYou from '../components/Stripe/ThankYou';


function Index(){
	return(
		<>
			<Routes>
				<Route path='/error-404' exact element={<Error />} />
				<Route path='/under-maintenance' exact element={<UnderMaintenance />} /> 
				<Route path='/coming-soon' exact element={<ComingSoon />} />
				<Route  element={<MainLayout />} > 
					<Route path='/' exact element={<Home />} />
					<Route path='/about-us' exact element={<AboutUs />} />
					<Route path='/products' exact element={<Project />} />
					<Route path='/product-details/:raise_id' exact element={<FundraiserDetail />} />
					<Route path='/contact-us' exact element={<ContactUs />} />
					<Route path='/payment' exact element={<Stripe />} />
					<Route path='/thank-you' exact element={<ThankYou />} />
				</Route>
			</Routes>	
			<ScrollToTop />
		</>
		 		
	)
} 

function MainLayout(){
	
	return (
		<div className="page-wraper">
			<Header />
			<Outlet /> 
			<Footer />
		</div>
	)
  
  };
export default Index;