import React from "react";
import { Link } from "react-router-dom";

import { getImageUrl, getClubImageUrl } from "../../services/helper";
import avat1 from "../../assets/images/avatar/avatar1.jpg";
import Pagination from "../Pagination";
import { donatePrice, currency } from "../../constant/theme";
import { useTranslation } from 'react-i18next';


const ProjectCard = ({ projects, total, current, pagination }) => {
  console.log(projects)
  const { t } = useTranslation();

  return (
    <>
      <div className="row">
            {projects.map((item, index) => {
              let salesArr = [];
              if(item?.salesArr && item?.salesArr.length > 0){
                item?.salesArr?.map((v) => {
                  if(v?.gridids){
                    salesArr.push(v.gridids)
                  }
                  return v.gridids
                })
              }
              salesArr = salesArr.length > 0 ? salesArr.join(", ").split(", ") : [];
              return (
                <div className='col-md-4 col-sm-6' key={item._id}>
                                <div className="p_card">
                                    <div className="p_media">
                                        <p className='p_price_tag'>{currency}{item?.total_amount} <small> { t('detail.goal') }</small></p>
                                        <Link to={`/product-details/${item._id}`}>
                                        {item?.is_completed === 0 ? (
                                          <>
                                          <img
                                            src={`${getImageUrl(`image-${item?._id}.png`)}?${new Date()}`}
                                            alt=""
                                          />
                                          </>
                                        ) : (
                                          <img
                                            src={getImageUrl(item.image)}
                                            alt=""
                                          />
                                        )}
                                      </Link>
                                    </div>
                                    <div className="p_info">
                                        <h5 className="p_title">
                                        <Link to={`/product-details/${item._id}`}>
                                          {item.name}
                                        </Link>                                            
                                        </h5>
                                        <p>{item.description.substring(0, 100)}</p>
                                        <div className="author-wrappper">
                                            <div className="author-media">
                                                <img
                                              src={
                                                item?.club_logo
                                                  ? getClubImageUrl(item?.club_logo)
                                                  : avat1
                                              }
                                              alt=""
                                            />
                                            </div>
                                            <div className="author-content">
                                                <div className="author-head">
                                                    <h6 className="author-name">{item?.club_name || "Auther"}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p_price'>
                                            <span className='p_bar'></span>
                                            <p>{currency}{item?.raised_amount} <small>{ t('detail.donated') } </small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
              );
            })}
          </div>
          <Pagination
            total={total}
            current={current}
            pagination={pagination}
          />
          
    </>
  );
};

export default ProjectCard;
