import React from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { config, socialLinks } from '../constant/theme';

const FooterCommonData = ({logoImage, iconStyle}) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="col-xl-4 col-lg-12 col-md-12">
                <div className="widget widget_about">
                    <div className="footer-logo logo-white">
                        <Link to={"/"}><img src={logoImage} alt="columnpix" title="columnpix" style={{width:"178px", height:"56px"}}/></Link> 
                    </div>
                    <p>{ t('header.about') }</p>
                    <div className="dz-social-icon style-1">
                        {/* <ul>
                            <li><a target="_blank" className="fab fa-facebook-f"  rel="noreferrer" href={socialLinks?.facebook}>{" "}</a></li>
                            <li><a target="_blank" className="fab fa-instagram"  rel="noreferrer" href={socialLinks?.instagram}>{" "}</a></li>
                            <li><a target="_blank" className="fab fa-twitter"  rel="noreferrer" href={socialLinks?.twitter}>{" "}</a></li>
                            <li><a target="_blank" className="fab fa-youtube"  rel="noreferrer" href={socialLinks?.youtube}>{" "}</a></li>
                        </ul> */}
                    </div>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-3 col-6">
                <div className="widget widget_services">
                    <h5 className="footer-title">{ t('menu.resources') }</h5>
                    <ul>
                        <li><Link to={"/products"}>{ t('menu.products') }</Link></li>
                        {/* <li><Link to={"/terms-and-condition"}>Terms And Condition</Link></li> */}
                    </ul>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-3 col-6">
                <div className="widget widget_services">
                    <h5 className="footer-title">{ t('menu.company') }</h5>
                    <ul>
                        <li><Link to={"/about-us"}>{ t('menu.aboutus') }</Link></li>
                        <li><Link to={"/contact-us"}>{ t('menu.contactus') }</Link></li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="widget widget_about">
                    <h5 className="footer-title">{ t('menu.get_in_touch') }</h5>
                    <div className="dz-social-icon style-1">
                        <ul>
                            <li>
                                <a className="fa fa-envelope" href={`mailto:${config?.email}`} target="_blank" rel="noreferrer">
                                {/* <span>{config?.email}</span> */}
                                </a>
                            </li>
                            <li>
                                <a className="fa fa-brands fa-whatsapp" href={`https://wa.me/${config?.whatsappFull}`} target="_blank" rel="noreferrer">
                                {/* <span>{config?.whatsapp}</span> */}
                                </a>
                            </li>
                            <li>
                                <a className="fa fa-brands fa-instagram" href={`https://instagram.com/columpix?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr`} target="_blank" rel="noreferrer">
                                {/* <span>{config?.whatsapp}</span> */}
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div> 
        </>
    );
};

export default FooterCommonData;
