import React from 'react';
import PropTypes from 'prop-types';

const ErrorElement = ({ error, errorMessage, name }) => {
  return (
    <>
      {error && (
        <p
          id={`${name}-error`}
          className={'text-danger'}
          data-testid={`${name}-error`}
        >
          {errorMessage}
        </p>
      )}
      </>
  );
};

ErrorElement.propTypes = {
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  errorMessage: PropTypes.string
};

ErrorElement.defaultProps = {
  error: undefined,
  errorMessage: ''
};

export default ErrorElement;
