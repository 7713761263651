import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from 'react-i18next';
import { showMessage } from "../services/helper";
import { contactRequest } from "../services/validations";
import ErrorElement from "../components/ErrorElement";
import { postApi } from "../services/appUtilsAxios";

import PageBanner from '../layouts/PageBanner';
import PartnershipSlider from './../components/Home/PartnershipSlider';
// import UpdateBlog from './../components/Home/UpdateBlog';

import bg from '../assets/images/banner/bnr1.jpg';
import shape1 from '../assets/images/pattern/shape1.png';
import shape3 from '../assets/images/pattern/shape3.png';
import shape5 from '../assets/images/pattern/shape5.png';
import shape6 from '../assets/images/pattern/shape6.png';
import shape7 from '../assets/images/pattern/shape7.png';
import { config } from '../constant/theme';
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    shouldFocusError: false,
    resolver: yupResolver(contactRequest),
    mode: "onChange",
  });
  const onFormSubmit = async (values) => {
    await postApi('add-contactus', values)
    .then((res)=>{
      if(res.status === 1){
        showMessage(1, res?.message || "Request sent successfull");
        reset();
      }
    })
    .catch((error)=>{
      console.log('error', error)
      showMessage(0, error?.message);
    });
  };

    return (
        <>

<Helmet>
<link rel="canonical" href='https://columpix.com/contact-us' />
  <script type="application/ld+json">
  {`
  {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home",
      "item": "https://columpix.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Contact Us",
      "item": "https://columpix.com/contact-us"  
    }]
  }
             
  `}
  </script>
  </Helmet>

            <div className="page-content bg-white">
                <PageBanner maintitle={ t('menu.home') } pagetitle={ t('menu.contactus') } background={bg}/>
                <section className="content-inner-1 bg-light section-pattren1 contact_section">
                    <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-4 col-md-6 m-b20">
                              <div className="icon-bx-wraper box-hover style-3">
                                  <div className="icon-lg"> 
                                      <a href={`https://wa.me/${config?.whatsappFull}`} className="icon-cell" target="_blank" rel="noreferrer">
                                        <i className="fa fa-brands fa-whatsapp"></i>
                                      </a> 
                                  </div>
                                  <div className="icon-content">
                                      <h4 className="dz-tilte m-b5">{t('contactus.trusted_partner')}</h4>
                                      <a className="fa fa-brands fa-whatsapp" href={`https://wa.me/${config.whatsappFull}`} target="_blank" rel="noreferrer"></a>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-6 m-b20">
                            <div className="icon-bx-wraper box-hover style-3">
                              <div className="icon-lg"> 
                                <a href={`mailto:${config?.email}`} className="icon-cell" target="_blank" rel="noreferrer">
                                  <i className="flaticon-email"></i>
                                </a> 
                              </div>
                              <div className="icon-content">
                                <h4 className="dz-tilte m-b5">{t('contactus.mail')}</h4>
                                <a className="" href={`mailto:${config?.email}`} target="_blank" rel="noreferrer"><span>{config?.email}</span></a>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <img src={shape1} className="shape-1 move-1" alt="shape" title="shape" />
                    <img src={shape3} className="shape-3 move-1" alt="shape" title="shape" />
                    <img src={shape5} className="shape-4 rotating" alt="shape" title="shape"/>
                    <img src={shape6} className="shape-5 rotating" alt="shape" title="shape"/>
                </section>
                <section className="content-inner map-wrapper1 contact_section">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 col-sm-12">
                                <div className="contact-info style-1">{/* form-wrapper  */}
                                    <h2 className="title text-center">{ t('contactus.write_message') }</h2>
                                    <div className="contact-area">
                                        <form className="dz-form dzForm contact-bx" onSubmit={handleSubmit(onFormSubmit)}>
                                            <div className="dzFormMsg"></div>
                                            <input type="hidden" className="form-control" name="dzToDo" value="Contact" />
                                            <div className="row sp15">
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <label className="form-label">{ t('contactus.form.first_name') }</label>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" placeholder="John"
                                                            control={control}
                                                            {...register('first_name')}
                                                            />
                                                        </div>
                                                        <ErrorElement
                                                          name="first_name"
                                                          error={!!errors.first_name}
                                                          errorMessage={errors?.first_name?.message}
                                                        />
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-md-6">
                                                  <div className='form-group'>
                                                    <label className="form-label">{ t('contactus.form.last_name') }</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Deo"
                                                        control={control}
                                                        {...register('last_name')}
                                                        />
                                                    </div>
                                                    <ErrorElement
                                                      name="last_name"
                                                      error={!!errors.last_name}
                                                      errorMessage={errors?.last_name?.message}
                                                    />
                                                  </div>  
                                                </div>
                                                <div className="col-md-6">
                                                  <div className='form-group'>
                                                    <label className="form-label">{ t('contactus.form.email') }</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="info@example.com"
                                                        control={control}
                                                        {...register('email')}
                                                        />
                                                    </div>
                                                    <ErrorElement
                                                      name="email"
                                                      error={!!errors.email}
                                                      errorMessage={errors?.email?.message}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className='form-group'>
                                                    <label className="form-label">{ t('contactus.form.phone') }</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="987 654 3210"
                                                        control={control}
                                                        {...register('phone')}
                                                        />
                                                    </div>
                                                    <ErrorElement
                                                      name="phone"
                                                      error={!!errors.phone}
                                                      errorMessage={errors?.phone?.message}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-12">
                                                  <div className='form-group'>
                                                    <label className="form-label">{ t('contactus.form.message') }</label>
                                                    <div className="input-group">
                                                        <textarea rows="7" className="form-control" placeholder="Dear Sir/Madam"
                                                        control={control}
                                                        {...register('message')}
                                                        ></textarea>
                                                    </div>
                                                    <ErrorElement
                                                      name="message"
                                                      error={!!errors.message}
                                                      errorMessage={errors?.message?.message}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="input-recaptcha">
                                                        {/* <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                        <input className="form-control d-none" style={{display:"none"}} data-recaptcha="true" required data-error="Please complete the Captcha" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button name="submit" type="submit" value="Submit" className="btn btn-secondary">{ t('contactus.form.submit') }</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>	
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={shape5} className="shape-2 move-2" alt="shape" title="shape"/>
                    <img src={shape7} className="shape-1 move-2" alt="shape" title="shape"/>
                    <img src={shape6} className="shape-3 move-2" alt="shape" title="shape"/>
                </section>
                {/* <section className="clients-wrapper p-0">
                    <div className="container">
                        <div className="section-head text-center">
                            <h3 className="title">Our Partnership</h3>
                        </div>
                        <PartnershipSlider />
                    </div>
                </section> */}
                {/* <div className="call-action style-1 content-inner-1">
			        <div className="container">
                        <UpdateBlog />
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default ContactUs;