import React,{useContext, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from "../context/ThemeContext";
// import BanerLogoSlider from './../components/Home/BanerLogoSlider';
import TestimonialSlider from './../components/Home/TestimonialSlider';
import StoryBlog from './../components/Home/StoryBlog';
import CounterBlog from './../components/Home/CounterBlog';
import DonateModal from './../components/Modal/DonateModal';

//images
import { IMAGES } from '../constant/theme';
import StoryBlog2 from '../components/Home/StoryBlog2';
import { Helmet } from "react-helmet";

const Home = () => {
    const { t } = useTranslation();
    const modalRef = useRef();
    const { changeBackground, changePrimaryColor } = useContext(ThemeContext);
    useEffect(() => {
        changeBackground({ value: "default", label: "default" });
        changePrimaryColor("color-skin-1");
    }, []);
    
    return(
        <>
        <Helmet>
            <link rel="canonical" href='https://columpix.com' />
        </Helmet>
            <div className="page-content bg-white">	
                <div className="banner-one">
                    <div className="container">
                        <div className="row banner-inner">
                            <div className="col-lg-5 col-md-5 order_2">
                                <div className="banner-media banner_media">
                                    <img src="/marko.png" className="main-img" alt="Simple, minimal fees" title="Simple, minimal fees" />
                                    <p className='marko-txt'><b>Marko Vukelic</b><br/>
                                    {t('home.section1.address1')},<br/>
                                    {t('home.section1.address2')},<br/>
                                    {t('home.section1.address3')}<br/>{t('home.section1.address4')}
                                    </p>
                                </div>
                                </div>
                                <div className="col-lg-7 col-md-7 order_1">
                                    <div className="banner-content">
                                        <h4 className="sub-title text-primary">{t('home.section1.title')}</h4>
                                        <h1 className="title">
                                            {t('home.section1.description')}
                                        </h1>
                                        <p className='mb-3'>{t('home.section1.text1')}</p>
                                        <p>{t('home.section1.text2')}</p>
                                        <Link to={"/about-us"} className="btn btn-secondary btnhover2 m-r15">{t('home.section1.learn_more')} <i className="flaticon-right-arrows ms-3 scale1">
                                            </i>
                                        </Link>
                                        {/* <BanerLogoSlider /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="dz-shape">
                                <img src={IMAGES.Shape1} className="shape-1 move-2" alt="shape" />
                                <img src={IMAGES.Shape2} className="shape-2 move-2" alt="shape" />
                                <img src={IMAGES.Shape1} className="shape-3 move-2" alt="shape" />
                                <img src={IMAGES.Shape2} className="shape-4 move-2" alt="shape" />
                            </div>
                        </div>
                    </div>

                    <section className="content-inner-3">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div class="quote-text">
                                        <p>{t('home.section1.text1')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="content-inner-3">
                        <div className="container">
                            <div className="row align-items-center project-bx left m-b30">
                                <div className="col-lg-6 col-md-12 order_2">
                                    <div className="dz-content wow fadeInUp" data-wow-delay="0.4s">
                                        <h3 className="title m-b15">{t('home.section2.title')}</h3>
                                        <p className="m-b0">{t('home.section2.description')}</p>
                                        <ul className="li-disc">
                                            <li>{t('home.section2.text1')}</li>
                                            <li>{t('home.section2.text2')}</li>
                                            <li>{t('home.section2.text3')}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 order_1 wow fadeInUp" data-wow-delay="0.2s">
                                    <div className="dz-media split-box split-active">
                                        <div>
                                            <img src="/GIF-3.gif" alt="Minimal Fees" title="Minimal Fees" className="app-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="content-inner-3">
                        <div class="container">
                            <div class="row align-items-center project-bx m-b30">
                                <div class="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.2s">
                                    <div class="dz-media split-box split-box2 split-active">
                                        <div>
                                            <img src="/GIF-2.gif" alt="Maximum excitement" title="Maximum excitement" class="app-1" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="dz-content wow fadeInUp" data-wow-delay="0.4s">
                                        <h3 class="title m-b15">{t('home.section3.title')}</h3>
                                        <p class="mb-3">{t('home.section3.description1')}</p>
                                        <p class="mb-3">{t('home.section3.description2')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="testimonial-wrapper1 content-inner">
                        <div className="section-head text-center wow fadeInUp" data-wow-delay="0.2s">
                            <h2 className="title">{t('home.testimonials.title')}</h2>
                            {/* <p>{t('home.testimonials.description')}</p> */}
                        </div>
                        <TestimonialSlider />
                    </section>
                    
                </div>
            <DonateModal 
                ref={modalRef}
            />
        </>
    )
}
export default Home;