/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

function Pagination(props) {
  const handlePagination = (current) => {
    props.pagination(current);
  };

  return (
    <div>
      {props.total > 1 && (<div className="row">
        <div className="col-12 m-sm-t0 m-t30 m-b15">
          <nav className="pagination-bx">
            <div className={`page-item ${
                  props.current === 1 ? "disabled" : props.current > 1 ? "" : ""
                }`}>
              <a href={"#"} className={`page-link prev`} onClick={() => handlePagination(props.current - 1)}>
                <i className="fas fa-chevron-left"></i>
              </a>
            </div>
            <ul className="pagination">
            {props.total < 7 ? (
              <>
                {Array.apply(0, Array(props.total)).map((arr, i) => (
                  <li
                    key={String(i)}
                    className={`page-item`}
                  >
                    <a
                      className={`page-link ${
                        props.current === i + 1 ? "active" : ""
                      }`}
                      href="#"
                      onClick={() => handlePagination(i + 1)}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
              </>
            ) : props.current % 5 >= 0 &&
              props.current > 4 &&
              props.current + 2 < props.total ? (
              <>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePagination(1)}
                  >
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link disabled" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePagination(props.current - 1)}
                  >
                    {props.current - 1}
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link active"
                    href="#"
                    onClick={() => handlePagination(props.current)}
                  >
                    {props.current}
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePagination(props.current + 1)}
                  >
                    {props.current + 1}
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link disabled" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePagination(props.total)}
                  >
                    {props.total}
                  </a>
                </li>
              </>
            ) : props.current % 5 >= 0 &&
              props.current > 4 &&
              props.current + 2 >= props.total ? (
              <>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePagination(1)}
                  >
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link disabled" href="#">
                    ...
                  </a>
                </li>
                <li
                  className={`page-item`}
                >
                  <a
                    className={`page-link ${
                      props.current === props.total - 3 ? "active" : ""
                    }`}
                    href="#"
                    onClick={() => handlePagination(props.total - 3)}
                  >
                    {props.total - 3}
                  </a>
                </li>
                <li
                  className={`page-item ${
                    props.current === props.total - 2 ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePagination(props.total - 2)}
                  >
                    {props.total - 2}
                  </a>
                </li>
                <li
                  className={`page-item`}
                >
                  <a
                    className={`page-link ${
                      props.current === props.total - 1 ? "active" : ""
                    }`}
                    href="#"
                    onClick={() => handlePagination(props.total - 1)}
                  >
                    {props.total - 1}
                  </a>
                </li>
                <li
                  className={`page-item`}
                >
                  <a
                    className={`page-link ${
                      props.current === props.total ? "active" : ""
                    }`}
                    href="#"
                    onClick={() => handlePagination(props.total)}
                  >
                    {props.total}
                  </a>
                </li>
              </>
            ) : (
              <>
                {Array.apply(0, Array(5)).map((arr, i) => (
                  <li
                    className={`page-item`}
                    key={String(i)}
                  >
                    <a
                      className={`page-link ${
                        props.current === i + 1 ? "active" : ""
                      }`}
                      href="#"
                      onClick={() => handlePagination(i + 1)}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
                <li className="page-item">
                  <a className="page-link disabled" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePagination(props.total)}
                  >
                    {props.total}
                  </a>
                </li>
              </>
            )}
            </ul>
            <div className={`page-item ${
                  props.current === props.total
                    ? "disabled"
                    : props.current < props.total
                    ? ""
                    : ""
                }`}>
              <Link to={"#"} className={`page-link next`} onClick={() => handlePagination(props.current + 1)}>
                <i className="fas fa-chevron-right"></i>
              </Link>
            </div>
          </nav>
        </div>
      </div>
      )}
    </div>
  );
}

export default Pagination;
