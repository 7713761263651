import { toast } from "react-hot-toast";

export const getImageUrl = (img) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}uploads/details/`;
  return `${baseUrl}${img}`;
}
export const getClubImageUrl = (img) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}uploads/club/`;
  return `${baseUrl}${img}`;
}
/* status=1 -> success, status=0 -> error */
export const showMessage = (status = 2, msg = "") => {
  if(status === 1){
    toast.success(msg);
  } else if(status === 0){
    toast.error(msg);
  }
};