import React from 'react';
import Route from './pages/Route';	
import posthog from 'posthog-js' // new
import { CookieBanner } from '@schlomoh/react-cookieconsent'


import "./assets/css/style.css";
//import "./assets/css/skin/skin-1.css";
import "swiper/css";

function App() {

  const Content = () => (
    <>
        <p>Diese Webseite verwendet Cookies. Mit dem Weitersufen akzeptierst du die Verwendung dieser Cookies. Weitere Infos dazu findest du im Datenschutz (This website uses cookies. By continuing to browse, you accept the use of these cookies. You can find more information about this in our privacy policy.)</p>
    </>
)

const containerStyle = {backgroundColor: '#333'};
const buttonStyle = {borderRadius: 0}
const primaryButtonStyle={...buttonStyle, backgroundColoe:'red'}
const secondaryButtonStyle={...buttonStyle, backgroundColoe:'blue'}

  return (
    <>
		  <Route />
      <CookieBanner 
            acceptButtonText='I want it'
            declineButtonText='Go away'
            headingColor='white'
            paragraphColor='grey'
            containerStyle={containerStyle}
            primaryButtonStyle={primaryButtonStyle}
            secondaryButtonStyle={secondaryButtonStyle}
            infoContent={<Content />}
        />


    </>
  );
}

export default App;
